import Settings from "@/services/settings/names";

class AuthService {
  constructor(
    settingsService,
    apiService,
    store,
    loggerService,
    authenticateUser,
    userType,
    userId,
    permissions
  ) {
    this.settingsService = settingsService;
    this.apiService = apiService;
    this.loggerService = loggerService;
    this.store = store;
    if (authenticateUser && userType && userId && permissions) {
      this.store.commit("SET_USER_ID", userId);
      this.store.commit("SET_USER_TYPE", userType);
      this.store.commit("SET_USER_AUTHENTICATED");
      this.store.commit("SET_USER_PERMISSIONS", permissions);
    }
  }
  async login(login, password, remember) {
    try {
      const { access, refresh, userType, permissions, userId } =
        await this.apiService.login(login, password);
      this.settingsService.set(Settings.AUTH_TOKEN, access, !remember);
      this.settingsService.set(Settings.REFRESH_TOKEN, refresh, !remember);
      this.settingsService.set(Settings.USER_TYPE, userType, !remember);
      this.settingsService.set(Settings.USER_ID, userId, !remember);
      this.settingsService.set(
        Settings.USER_PERMISSIONS,
        permissions,
        !remember
      );
      await this.store.commit("SET_USER_ID", userId);
      await this.store.commit("SET_USER_TYPE", userType);
      await this.store.commit("SET_USER_PERMISSIONS", permissions);
      await this.store.commit("SET_USER_AUTHENTICATED");
    } catch (e) {
      this.loggerService.error("login failed", e);
      await this.clearSession();
      throw e;
    }
  }
  async clearSession() {
    await this.store.commit("SET_USER_UNAUTHENTICATED");
    await this.store.commit("CLEAR_USER_TYPE");
    await this.store.commit("CLEAR_USER_ID");
    await this.store.commit("SET_USER_PERMISSIONS");
    this.settingsService.clear(Settings.AUTH_TOKEN);
    this.settingsService.clear(Settings.REFRESH_TOKEN);
    this.settingsService.clear(Settings.USER_TYPE);
    this.settingsService.clear(Settings.USER_ID);
    this.settingsService.clear(Settings.USER_PERMISSIONS);
  }
  async logout() {
    try {
      await this.clearSession();
      await this.apiService.logout();
    } catch (e) {
      this.loggerService.error("logout failed", e);
    }
  }
}

export default AuthService;
