import Vue from "vue";
import Vuex from "vuex";
import alerts from "./modules/alerts";
import UserRoles from "../utils/user-roles";

Vue.use(Vuex);

const debugMode = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  getters: {
    isNormalUser(state) {
      return state.userType === UserRoles.admin;
    },
    device(state) {
      return state.deviceInfo.name;
    },
    saving(state) {
      return state.savingActionsStack > 0;
    },
    loading(state) {
      return state.loadingActionsStack > 0;
    },
    lang(state) {
      return state.lang;
    },
    permissions(state) {
      return state.permissions;
    },
    allowedMenuItems(state) {
      return state.menu.filter(
        (mi) => mi.roles.includes(state.userType) && mi.visible
      );
    },
    userRole(state) {
      return state.userType;
    },
    userId(state) {
      return state.userId;
    },
  },
  state: {
    userId: null,
    userType: null,
    permissions: {},
    isUserAuthenticated: false,
    deviceInfo: {
      firmwareVersion: null,
      frameworkVersion: null,
      frontendVersion: null,
      apiVersion: null,
      type: null,
      name: null,
      serialNumber: null,
      model: null,
      commitHash: null,
      mode: null,
    },
    lang: null,
    savingActionsStack: 0,
    loadingActionsStack: 0,
    languages: [],
    menu: [],
    baseTitle: "",
    isUpdatesChecked: false,
  },
  actions: {
    SET_MENU({ commit }, menu) {
      commit("SET_MENU", menu);
    },
  },
  mutations: {
    SET_USER_UNAUTHENTICATED(state) {
      state.isUserAuthenticated = false;
    },
    SET_USER_AUTHENTICATED(state) {
      state.isUserAuthenticated = true;
    },
    SET_USER_TYPE(state, val) {
      state.userType = val;
    },
    SET_USER_ID(state, val) {
      state.userId = val;
    },
    SET_USER_PERMISSIONS(state, val) {
      state.permissions = val;
    },
    CLEAR_USER_TYPE(state) {
      state.userType = null;
    },
    CLEAR_USER_ID(state) {
      state.userId = null;
    },
    SET_UPDATES_CHECKED(state, val) {
      state.isUpdatesChecked = val;
    },
    SET_MENU(state, menu) {
      state.menu = menu;
    },
    SET_API_INFO(state, info) {
      state.deviceInfo = info;
    },
    SET_LOADING(state, status) {
      status ? state.loadingActionsStack++ : state.loadingActionsStack--;
    },
    SET_SAVING(state, status) {
      status ? state.savingActionsStack++ : state.savingActionsStack--;
    },
    SET_LANG(state, lng) {
      state.lang = lng;
    },
    SET_LANG_LIST(state, languages) {
      state.languages = languages;
    },
    SET_BASE_TITLE(state, title) {
      state.baseTitle = title;
    },
  },

  modules: {
    alerts,
  },
  strict: debugMode,
});
