export default {
  auth: {
    login: "/api/v0/login", // Login user into the system
    refresh: "/api/v0/refresh", // Take new token pair by refresh token
    logout: "/api/v0/logout", // Logout the current session
  },
  info: {
    countries: "/api/v0/info/countries",
  },
  users: {
    index: "/api/v0/users",
    item: "/api/v0/users/{id}",
  },
  actions: {
    index: "/api/v0/actions",
  },
  licenseTypes: {
    index: "/api/v0/license-types",
    item: "/api/v0/license-types/{id}",
  },
  licenseRequests: {
    index: "/api/v0/license-requests",
    item: "/api/v0/license-requests/{id}",
    changeStatus: "/api/v0/license-requests/{id}/status",
  },
  clients: {
    index: "/api/v0/clients",
    item: "/api/v0/clients/{id}",
  },
  projects: {
    index: "/api/v0/projects",
    item: "/api/v0/projects/{id}",
  },
  licenses: {
    index: "/api/v0/licenses",
    report: {
      json: "/api/v0/licenses/report",
      csv: "/api/v0/licenses/report/csv",
    },
    byProject: "/api/v0/licenses/project/{projectId}",
    item: {
      index: "/api/v0/licenses/{id}",
      file: "/api/v0/licenses/{id}/file",
    },
  },
  instances: {
    index: "/api/v0/instances",
  },
};
