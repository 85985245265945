import Vue from "vue";
import PageContainer from "@/components/common/page-container";
import EntityTable from "@/components/common/entity-table";
import FilterField from "@/components/common/filter-field";

export function registerComponents() {
  Vue.component("PageContainer", PageContainer);
  Vue.component("EntityTable", EntityTable);
  Vue.component("FilterField", FilterField);

  Vue.component("Alerts", () =>
    import(
      /* webpackChunkName: "common-controls" */ "./components/common/alerts"
    )
  );

  Vue.component("LangSwitcher", () =>
    import(
      /* webpackChunkName: "common-controls" */ "./components/common/lang-switcher"
    )
  );
  Vue.component("Loading", () =>
    import(
      /* webpackChunkName: "common-controls" */ "./components/common/loading"
    )
  );
}
