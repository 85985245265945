<template>
  <v-app app light>
    <v-progress-linear
      v-if="$store.getters.loading || $store.getters.saving"
      :indeterminate="$store.getters.loading || $store.getters.saving"
      color="accent"
      height="5"
      style="
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 90000;
        display: block;
        margin: 0;
      "
    />
    <alerts :items="$store.state.alerts.items" @close-alert="closeAlert" />
    <router-view />
    <div class="bs-select-container"></div>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "App",
  methods: {
    ...mapActions(["removeAlert"]),
    closeAlert(alert) {
      this.removeAlert(alert);
    },
  },
  beforeMount() {
    this.$store.commit("SET_BASE_TITLE", this.$t("interface.root_title"));
  },
};
</script>

<style>
.v-list-item--highlighted:not(.v-list-item--active)::before {
  opacity: 0 !important;
}
</style>
