<template>
  <div class="fill-height">
    <v-app-bar color="primary" class="white--text elevation-0">
      <v-toolbar-title class="d-flex">
        <icon-base
          icon-name="logo"
          height="34"
          width="142"
          view-box="0 0 142 34"
          class="basip-sidebar__logo"
        >
          <icon-logo />
        </icon-base>
      </v-toolbar-title>
      <v-toolbar-title> </v-toolbar-title>
      <v-spacer />
      <lang-switcher class="mr-3" />
    </v-app-bar>
    <v-main class="fill-height">
      <router-view />
    </v-main>
    <v-footer app color="primary" class="white--text">
      <v-layout justify-center class="px-2">
        <div>{{ $store.state.deviceInfo.name }}</div>
        <v-spacer />
        <div>{{ $store.state.deviceInfo.firmware_version || "" }}</div>
      </v-layout>
    </v-footer>
  </div>
</template>

<script>
import IconLogo from "./../components/common/icons/IconLogo";
import IconBase from "./../components/common/icons/IconBase";
import LangSwitcher from "./../components/common/lang-switcher";

export default {
  name: "EmptyLayout",
  components: {
    IconLogo,
    IconBase,
    LangSwitcher,
  },
};
</script>

<style scoped>
.min-height-100vh {
  min-height: 100vh;
}
</style>
