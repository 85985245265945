<template>
  <div class="">
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="miniVariant"
      :clipped="clipped"
      persistent
      fixed
      app
      width="250"
    >
      <div
        :class="{
          'justify-space-between': !miniVariant,
          'justify-center': miniVariant,
        }"
        class="layout align-center py-3 px-2 primary"
      >
        <icon-base
          v-show="!miniVariant"
          icon-name=""
          height="34"
          width="142"
          view-box="0 0 142 34"
          class="basip-sidebar__logo"
          @click="drawer = !drawer"
        >
          <icon-logo />
        </icon-base>
        <v-btn
          v-show="$vuetify.breakpoint.mdAndUp"
          icon
          @click="miniVariant = !miniVariant"
        >
          <v-icon color="white">{{
            miniVariant ? "chevron_right" : "chevron_left"
          }}</v-icon>
        </v-btn>
      </div>
      <v-divider />
      <v-list dense class="main-menu">
        <v-list-item
          v-for="(item, i) in $store.getters.allowedMenuItems"
          :key="i"
          :to="item.route"
          value="true"
        >
          <v-list-item-action>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" v-html="item.icon" />
              </template>
              <span>{{ $t("interface." + item.title) }}</span>
            </v-tooltip>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="black--text lighten-3">
              {{ $t("interface." + item.title) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      :clipped-left="clipped"
      app
      color="primary"
      class="white--text elevation-0"
    >
      <v-app-bar-nav-icon class="white--text" @click.stop="drawer = !drawer" />
      <v-toolbar-title>{{ toolbarTitle }}</v-toolbar-title>
      <v-spacer />
      <lang-switcher />
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon color="white">account_circle</v-icon>
          </v-btn>
          <span class="ml-2">{{
            $t("interface." + $store.state.userType)
          }}</span>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-icon>person_outline</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              {{ $store.state.userType | capitalize }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click.stop="logout">
            <v-list-item-action>
              <v-icon>logout</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              {{ $t("interface.logout") }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <v-container>
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import IconLogo from "./../components/common/icons/IconLogo";
import IconBase from "./../components/common/icons/IconBase";
import LangSwitcher from "./../components/common/lang-switcher";
import Container from "@/services/container";
import Services from "@/services/container/types";
import Events from "@/services/event-bus/events";

export default {
  name: "SidebarLayout",
  components: {
    IconLogo,
    IconBase,
    LangSwitcher,
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  data() {
    return {
      clipped: false,
      drawer: true,
      miniVariant: false,
      toolbarTitle: "",
    };
  },
  beforeMount() {
    const eventBus = Container.get(Services.EVENT_BUS);
    eventBus.subscribe(Events.PAGE_LOADED, this.changeToolbarTitle);
  },
  methods: {
    changeToolbarTitle(title) {
      this.toolbarTitle = title;
    },
    async logout() {
      const authService = Container.get(Services.AUTH);
      await authService.logout();
      this.$nextTick(() => {
        this.$router.push({ name: "login" });
      });
    },
  },
};
</script>

<style>
.main-menu .v-list__tile--active {
  background: rgba(0, 0, 0, 0.04);
}
</style>
