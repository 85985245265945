<script>
import { defaultRoutesForUsers } from "@/router";
import Container from "@/services/container";
import ServiceTypes from "@/services/container/types";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      login: "",
      password: "",
      remember: false,
      loading: false,
    };
  },
  methods: {
    async loginAttempt() {
      this.loading = true;
      const loggerService = Container.get(ServiceTypes.LOGGER);
      const authService = Container.get(ServiceTypes.AUTH);
      try {
        // trying to authenticate user by credentials
        await authService.login(this.login, this.password);
        // if it is ok, route to home page by user type, it can be different for user types
        this.$router.push({
          name: defaultRoutesForUsers[this.$store.state.userType],
        });
      } catch (e) {
        loggerService.error(e);
        this.$showError(this.$t("interface.incorrect_credentials"));
      }
      this.loading = false;
    },
  },
};
</script>

<template>
  <page-container :title="$t('interface.login')" class="fill-height">
    <v-layout align-center justify-center fill-height class="js-login-page">
      <v-flex
        :class="{ 'px-2': $vuetify.breakpoint.smAndDown }"
        xs12
        sm8
        md4
        style="max-width: 400px"
      >
        <v-card class="elevation-4">
          <v-toolbar dark color="primary" class="elevation-0">
            <v-toolbar-title>{{ $t("interface.sign_in") }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pb-0">
            <v-form>
              <v-text-field
                v-model="login"
                :label="$t('interface.login')"
                prepend-icon="person"
                placeholder=""
                type="text"
                class="js-login"
                @keypress.enter="loginAttempt"
              />
              <v-text-field
                v-model="password"
                :label="$t('interface.password')"
                prepend-icon="lock"
                placeholder=""
                required
                type="password"
                class="js-password"
                @keypress.enter="loginAttempt"
              />
            </v-form>
          </v-card-text>
          <v-card-actions class="pa-0">
            <div class="layout align-center px-4 pb-4">
              <v-checkbox
                v-model="remember"
                :label="$t('interface.remember_me')"
                class="js-remember mt-0"
                color="accent"
                hide-details
              />
              <v-spacer />
              <v-btn :loading="loading" color="accent" @click="loginAttempt">
                {{ $t("interface.sign_in_button") }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </page-container>
</template>
